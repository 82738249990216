var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CModal", {
    attrs: { centered: "", show: _vm.show },
    on: {
      "update:show": function ($event) {
        return _vm.$emit("update:show", $event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "header-wrapper",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between border-bottom px-4 py-2",
              },
              [
                _c("span", [_vm._v("Отправка одиночного сообщения в тг")]),
                _c(
                  "div",
                  {
                    staticClass: "curatorModal__header-close",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v("×")]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body-wrapper",
        fn: function () {
          return [
            _c("c-form", { staticClass: "p-4" }, [
              _c(
                "div",
                { staticClass: "user-select" },
                [
                  _c("v-select-input", {
                    attrs: {
                      options: _vm.userList,
                      clearable: false,
                      "get-option-label": (option) => option.email,
                      placeholder: _vm.$t(
                        "lk.training.curatorModal.emailPlaceholder"
                      ),
                    },
                    on: { search: _vm.onSearch },
                    scopedSlots: _vm._u([
                      {
                        key: "prepend",
                        fn: function () {
                          return [_c("CIcon", { attrs: { name: "mail" } })]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-options",
                        fn: function () {
                          return [
                            _vm._v(
                              " " + _vm._s(_vm.$t("general.emptySearch")) + " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.user,
                      callback: function ($$v) {
                        _vm.user = $$v
                      },
                      expression: "user",
                    },
                  }),
                ],
                1
              ),
              _vm.user
                ? _c("div", { staticClass: "mb-2" }, [
                    _vm._v(
                      " tg username: " + _vm._s(_vm.user.tg_nick || "-") + " "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "message-text" },
                [
                  _c("c-textarea", {
                    attrs: { placeholder: "Сообщение" },
                    model: {
                      value: _vm.message,
                      callback: function ($$v) {
                        _vm.message = $$v
                      },
                      expression: "message",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer-wrapper",
        fn: function () {
          return [
            _c(
              "c-loading-button-custom",
              {
                attrs: { loading: _vm.loading, color: "primary" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Отправить ")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }