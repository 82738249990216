var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      attrs: {
        centered: "",
        scrollable: "",
        closeOnBackdrop: false,
        title: _vm.$t("lk.tg_notifications.errorsList"),
        "add-content-classes": "ccontent",
        size: "lg",
        show: _vm.showErrorsModal,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$emit("closeErrorsModal")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "d-flex w-100 m-0" },
                [
                  _c(
                    "CButton",
                    {
                      staticClass: "cbtn flex-grow-1",
                      attrs: { color: "dark" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeErrorsModal")
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("general.close")) + " ")]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CDataTable", {
        staticClass: "c-data-table h-100 overflow-hidden",
        attrs: {
          fields: _vm.fieldsErrors,
          items: _vm.activeItemErrorsList,
          noItemsView: {
            noResults: _vm.$t("general.noData"),
            noItems: _vm.$t("general.noData"),
          },
        },
        scopedSlots: _vm._u([
          {
            key: "telegram",
            fn: function ({ item }) {
              return [
                _c("td", [
                  item.telegram
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: "https://t.me/" + item.telegram,
                            target: "_blank",
                          },
                        },
                        [_vm._v("@" + _vm._s(item.telegram))]
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm._v(_vm._s(item.name) + " "),
                  _c("br"),
                  _vm._v(_vm._s(item.email) + " "),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }