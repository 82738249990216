<template>
  <CModal
    centered
    scrollable
    :closeOnBackdrop="false"
    :title="$t('lk.tg_notifications.errorsList')"
    add-content-classes="ccontent"
    size="lg"
    :show="showErrorsModal"
    @update:show="$emit('closeErrorsModal')"
  >
    <CDataTable
      :fields="fieldsErrors"
      :items="activeItemErrorsList"
      :noItemsView="{ noResults: $t('general.noData'), noItems: $t('general.noData') }"
      class="c-data-table h-100 overflow-hidden"
    >
      <template #telegram="{item}">
        <td>
          <a v-if="item.telegram" :href="'https://t.me/' + item.telegram" target="_blank">@{{ item.telegram }}</a>
          <br>{{ item.name }}
          <br>{{ item.email }}
        </td>
      </template>
    </CDataTable>

    <template v-slot:footer>
      <div class="d-flex w-100 m-0">
        <CButton
          class="cbtn flex-grow-1"
          color="dark"
          @click="$emit('closeErrorsModal')"
        >
          {{ $t('general.close') }}
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "ErrorsModal",
  props: {
    showErrorsModal: {
      type: Boolean,
      default: false
    },
    activeItem: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      fieldsErrors: [
        {key: 'telegram', label: this.$t('lk.tg_notifications.fieldsErrors.user')},
        {key: 'error', label: this.$t('lk.tg_notifications.fieldsErrors.error')},
      ],
    }
  },
  computed: {
    activeItemErrorsList() {
      return this.activeItem?.fails || []
    },
  },
}
</script>

<style scoped lang="scss">
.c-data-table {
  /deep/ {
    table {
      min-width: 400px;
    }

    thead {
      th {
        position: sticky;
        z-index: 1;
        top: -2px;
        background: var(--second-card-bg);
      }
    }

    .table-responsive {
      height: 100%;
    }
  }
}
</style>
