var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCard",
    {
      staticClass: "ccard shadow-none h-100 overflow-hidden",
      attrs: { color: "second-card-bg" },
    },
    [
      _c(
        "CCardBody",
        { staticClass: "ccard-body d-flex flex-column h-100" },
        [
          _vm.items && _vm.items.length
            ? _c("CDataTable", {
                staticClass: "c-data-table h-100 overflow-hidden",
                attrs: { fields: _vm.fields, items: _vm.items },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "messageStrip",
                      fn: function ({ item }) {
                        return [
                          _c("td", {
                            domProps: { innerHTML: _vm._s(item.messageStrip) },
                          }),
                        ]
                      },
                    },
                    {
                      key: "data",
                      fn: function ({ item }) {
                        return [
                          item.send_at || item.fails_count
                            ? _c(
                                "td",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lk.tg_notifications.whoSent")
                                      ) +
                                      ": " +
                                      _vm._s(item.userName)
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "lk.tg_notifications.dateTimeOfDispatch"
                                        )
                                      ) +
                                      ": " +
                                      _vm._s(item.sendAt)
                                  ),
                                  _c("br"),
                                  _vm.isOneReceiver(item)
                                    ? _c("span", [
                                        _vm._v(
                                          "Получатель: " +
                                            _vm._s(
                                              item.success[0].tg_nick ?? "-"
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("br"),
                                  _c("span", { staticClass: "text-success" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lk.tg_notifications.delivered")
                                      ) +
                                        ": " +
                                        _vm._s(item.success_count)
                                    ),
                                  ]),
                                  _c("br"),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("lk.tg_notifications.errors")
                                      ) +
                                        ": " +
                                        _vm._s(item.fails_count)
                                    ),
                                  ]),
                                  item.fails_count
                                    ? [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "list-button ml-2",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setActiveItem(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "lk.tg_notifications.list"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(item.userName) +
                                    " " +
                                    _vm._s(item.updatedAt) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "td",
                            { staticClass: "text-right text-nowrap" },
                            [
                              (!item.success_count && item.total_count > 1) ||
                              !item.send_at
                                ? _c(
                                    "CButton",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        color: "success",
                                        disabled: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.$emit(
                                            "openShowModal",
                                            item.copy()
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: { name: "cil-pencil" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "CButton",
                                {
                                  attrs: {
                                    color: "danger",
                                    disabled: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.$emit(
                                        "openRemoveConfirm",
                                        item.copy()
                                      )
                                    },
                                  },
                                },
                                [_c("CIcon", { attrs: { name: "cil-x" } })],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  765899870
                ),
              })
            : _c("p", { staticClass: "m-0" }, [
                _vm._v(_vm._s(_vm.$t("lk.tg_notifications.empty"))),
              ]),
          _c("CPagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalPages > 1,
                expression: "totalPages > 1",
              },
            ],
            staticClass: "mt-2 pagination-custom",
            class: { _disabled: _vm.loading },
            attrs: { pages: _vm.totalPages, activePage: _vm.page },
            on: {
              "update:activePage": function ($event) {
                _vm.page = $event
              },
              "update:active-page": function ($event) {
                _vm.page = $event
              },
            },
          }),
        ],
        1
      ),
      _c("errors-modal", {
        attrs: {
          "active-item": _vm.activeItem,
          "show-errors-modal": _vm.showErrorsModal,
        },
        on: { closeErrorsModal: _vm.closeErrorsModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }