<template>
  <CCard
    color="second-card-bg"
    class="ccard shadow-none h-100 overflow-hidden"
  >
    <CCardBody class="ccard-body d-flex flex-column h-100">
      <CDataTable
        v-if="items && items.length"
        :fields="fields"
        :items="items"
        class="c-data-table h-100 overflow-hidden"
      >
        <template #messageStrip="{item}">
          <td v-html="item.messageStrip"></td>
        </template>
        <template #data="{item}">
          <td v-if="item.send_at || item.fails_count">
            {{ $t('lk.tg_notifications.whoSent') }}: {{item.userName}}<br>
            {{ $t('lk.tg_notifications.dateTimeOfDispatch') }}: {{item.sendAt}}<br>
            <span v-if="isOneReceiver(item)">Получатель: {{ item.success[0].tg_nick ?? '-' }}</span><br>
            <span class="text-success">{{ $t('lk.tg_notifications.delivered') }}: {{item.success_count}}</span>
            <br><span class="text-danger">{{ $t('lk.tg_notifications.errors') }}: {{item.fails_count}}</span>
            <template v-if="item.fails_count">
              <button
                class="list-button ml-2"
                @click="setActiveItem(item)"
              >
                {{ $t('lk.tg_notifications.list') }}
              </button>
            </template>
          </td>
          <td v-else>
            {{item.userName}} {{item.updatedAt}}
          </td>
        </template>
        <template #actions="{item}">
          <td class="text-right text-nowrap">
            <CButton
              v-if="(!item.success_count && item.total_count > 1) || !item.send_at"
              class="mr-1"
              color="success"
              :disabled="loading"
              @click="$emit('openShowModal', item.copy())"
            >
              <CIcon name="cil-pencil" />
            </CButton>
            <CButton
              color="danger"
              :disabled="loading"
              @click="$emit('openRemoveConfirm', item.copy())"
            >
              <CIcon name="cil-x" />
            </CButton>
          </td>
        </template>
      </CDataTable>
      <p v-else class="m-0">{{ $t('lk.tg_notifications.empty') }}</p>
      <CPagination
        class="mt-2 pagination-custom"
        :class="{_disabled: loading}"
        v-show="totalPages > 1"
        :pages="totalPages"
        :activePage.sync="page"
      />
    </CCardBody>

    <errors-modal
      :active-item="activeItem"
      :show-errors-modal="showErrorsModal"
      @closeErrorsModal="closeErrorsModal"
    />
  </CCard>
</template>

<script>
import TgNotification from "@/assets/js/tg_notifications/TgNotification.class";
import ErrorsModal from "@/components/tg_notifications/ErrorsModal";

export default {
  name: "TgNotificationsTable",
  components: {
    ErrorsModal,
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    totalPages: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      TgNotification,
      page: 1,
      fields: [
        { key: 'messageStrip', label: this.$t('lk.tg_notifications.message') },
        { key: 'data', label: this.$t('lk.tg_notifications.status') },
        { key: 'actions', label: '' }
      ],
      showErrorsModal: false,
      activeItem: null,
    }
  },
  methods: {
    isOneReceiver(item) {
      return item.success.length === 1 && item?.success?.[0]?.tg_nick
    },
    deleteItem (notification, e) {
      e.target.blur()
      notification.delete()
        .then(({status}) => {
          if (status < 300) {
            //this.getNotifications()
          }
        })
    },
    setActiveItem(item) {
      this.activeItem = item
      this.showErrorsModal = !!item
    },
    closeErrorsModal() {
      this.showErrorsModal = false

      setTimeout(() => {
        this.activeItem = null
      }, 300)
    },
  },
  watch: {
    page (val, oldVal) {
      this.loading = true
      if(val && val !== oldVal) {
        this.$emit('change-page', this.page)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ccard {
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }
  @include media-breakpoint-down(sm) {
    margin: 0 -1.25rem;
  }
  @include media-breakpoint-up(sm) {
    margin: 0 -2rem;

    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.c-data-table {
  /deep/ {
    thead {
      th {
        position: sticky;
        z-index: 1;
        top: -1px;
        background: var(--second-card-bg);
      }
    }

    .table-responsive {
      height: 100%;
    }
  }
}

.list-button {
  background: none;
  border: none;
  padding: 0;
  color: inherit;

  &:hover {
    text-decoration: underline;
  }
}
</style>
