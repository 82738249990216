var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tg-notify d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "h1",
            { staticClass: "title" },
            [
              _vm._v(" " + _vm._s(_vm.$t("lk.tg_notifications.title")) + " "),
              _vm.loading
                ? _c("CSpinner", {
                    staticClass: "ml-3",
                    attrs: { color: "primary" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "CButton",
            {
              staticClass: "cbtn px-4",
              attrs: { color: "primary", disabled: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.showSendMessageModal = true
                },
              },
            },
            [_vm._v(" Отправить одиночное сообщение ")]
          ),
          _c(
            "CButton",
            {
              staticClass: "cbtn px-4",
              attrs: { color: "primary", disabled: _vm.loading },
              on: {
                click: function ($event) {
                  _vm.showModal = true
                },
              },
            },
            [
              _c("CIcon", { attrs: { name: "cil-plus" } }),
              _vm._v(" " + _vm._s(_vm.$t("lk.tg_notifications.add")) + " "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CModal",
        {
          attrs: {
            centered: "",
            closeOnBackdrop: false,
            title: _vm.$t("lk.tg_notifications.titleModal"),
            "add-content-classes": "ccontent",
            show: _vm.showModal,
          },
          on: { "update:show": _vm.closeEvent },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "d-flex w-100 m-0" },
                    [
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "cbtn mr-2 flex-grow-1",
                          attrs: {
                            color: "danger",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showSendingConfirm = true
                            },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "my-0 mr-1",
                            attrs: { name: "cil-bullhorn" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.tg_notifications.send")) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CLoadingButtonCustom",
                        {
                          staticClass: "cbtn mr-2 flex-grow-1",
                          attrs: {
                            color: "success",
                            loading: _vm.loading,
                            disabled: _vm.loading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.save(false)
                            },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "my-0 mr-1",
                            attrs: { name: "cil-save" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.tg_notifications.save")) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CButton",
                        {
                          staticClass: "cbtn flex-grow-1",
                          attrs: { color: "dark", disabled: _vm.loading },
                          on: { click: _vm.closeEvent },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lk.tg_notifications.cancel")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "form-group mb-4" }, [
            _c(
              "div",
              { staticClass: "mt-2 c-textarea-wrap" },
              [
                _c("CTextarea", {
                  staticClass: "c-textarea",
                  attrs: {
                    rows: "6",
                    placeholder: _vm.$t("lk.tg_notifications.message"),
                  },
                  model: {
                    value: _vm.notification.message,
                    callback: function ($$v) {
                      _vm.$set(_vm.notification, "message", $$v)
                    },
                    expression: "notification.message",
                  },
                }),
                _c(
                  "button",
                  {
                    ref: "emoji-trigger",
                    staticClass: "emoji-trigger",
                    on: { click: _vm.toggleEmojiPicker },
                  },
                  [_c("CIcon", { attrs: { name: "cil-happy" } })],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("CModal", {
        attrs: {
          centered: "",
          closeOnBackdrop: true,
          title: _vm.$t("lk.tg_notifications.titleModal2"),
          "add-content-classes": "ccontent",
          show: _vm.showSendingConfirm,
        },
        on: { "update:show": _vm.closeEvent },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 m-0" },
                  [
                    _c(
                      "CLoadingButtonCustom",
                      {
                        staticClass: "cbtn mr-2 flex-grow-1",
                        attrs: {
                          color: "danger",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.save(true)
                          },
                        },
                      },
                      [
                        _c("CIcon", {
                          staticClass: "my-0 mr-1",
                          attrs: { name: "cil-bullhorn" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("lk.tg_notifications.send")) + " "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "cbtn flex-grow-1",
                        attrs: { color: "dark", disabled: _vm.loading },
                        on: {
                          click: function ($event) {
                            _vm.showSendingConfirm = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.tg_notifications.cancel")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("CModal", {
        attrs: {
          centered: "",
          closeOnBackdrop: true,
          title: _vm.$t("lk.tg_notifications.titleModal2"),
          "add-content-classes": "ccontent",
          show: _vm.showRemoveConfirm,
        },
        on: { "update:show": _vm.closeEvent },
        scopedSlots: _vm._u([
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex w-100 m-0" },
                  [
                    _c(
                      "CLoadingButtonCustom",
                      {
                        staticClass: "cbtn mr-2 flex-grow-1",
                        attrs: {
                          color: "danger",
                          loading: _vm.loading,
                          disabled: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.remove.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.tg_notifications.delete")) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "cbtn flex-grow-1",
                        attrs: { color: "dark", disabled: _vm.loading },
                        on: { click: _vm.closeEvent },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lk.tg_notifications.cancel")) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("SendUserMessage", {
        attrs: { show: _vm.showSendMessageModal },
        on: {
          close: function ($event) {
            _vm.showSendMessageModal = false
          },
          "update:show": function ($event) {
            _vm.showSendMessageModal = $event
          },
          success: function ($event) {
            return _vm.getItems()
          },
        },
      }),
      _c("tg-notifications-table", {
        ref: "table",
        attrs: {
          items: _vm.items,
          loading: _vm.loading,
          totalPages: _vm.totalPages,
        },
        on: {
          "change-page": _vm.changePage,
          openRemoveConfirm: function ($event) {
            _vm.showRemoveConfirm = true
            _vm.notification = $event ? $event : null
          },
          openShowModal: function ($event) {
            _vm.showModal = true
            _vm.notification = $event ? $event : null
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }