import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

export default class TgNotification {
  constructor (init = {}) {
    this.id = init?.id || null
    this.message = init?.message || ''
    this.user = init?.user || null
    this.total_count = init?.total_count || 0
    this.success = init.success || []
    this.success_count = init?.success_count || 0
    this.fails_count = init?.fails_count || 0
    this.fails = init?.fails || []
    this.send_at = init?.send_at || null
    this.updated_at = init?.updated_at || null
    this.created_at = init?.created_at || null
  }

  get userName () {
    return this.user && this.user.name
  }

  get messageStrip() {
    return this.message.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll(/\r\n|\r|\n/g, "<br>")
  }

  get updatedAt() {
    return this.updated_at ? moment(this.updated_at).utc(true).format('DD.MM.YYYY HH:mm') : ''
  }

  get sendAt() {
    return this.send_at ? moment(this.send_at).utc(true).format('DD.MM.YYYY HH:mm') : ''
  }

  dataToSave (isSending) {
    const obj = {}
    obj.message = this.message
    if(isSending){
      obj.isSending = true
    }
    return obj
  }
  save (isSending) {
    return axios.post('api/v1/tg_notifications' + (this.id ? '/' + this.id : ''), this.dataToSave(isSending))
  }
  delete () {
    return axios.delete('api/v1/tg_notifications/' + this.id)
  }
  copy () {
    return new TgNotification(cloneDeep(this))
  }
}
