<template>
  <CModal centered :show="show" @update:show="$emit('update:show', $event)">
    <template #header-wrapper>
      <div class="d-flex justify-content-between border-bottom px-4 py-2">
        <span>Отправка одиночного сообщения в тг</span>
        <div @click="$emit('close')" class="curatorModal__header-close">×</div>
      </div>
    </template>
    <template #body-wrapper>
      <c-form class="p-4">
        <div class="user-select">
          <v-select-input
            v-model="user"
            :options="userList"
            :clearable="false"
            :get-option-label="(option) => option.email"
            :placeholder="$t('lk.training.curatorModal.emailPlaceholder')"
            @search="onSearch"
          >
            <template #prepend>
              <CIcon name="mail"/>
            </template>
            <template #no-options>
              {{ $t('general.emptySearch') }}
            </template>
          </v-select-input>
        </div>
        <div v-if="user" class="mb-2">
          tg username: {{ user.tg_nick || '-' }}
        </div>
        <div class="message-text">
          <c-textarea
            placeholder="Сообщение"
            v-model="message"
          />
        </div>
      </c-form>
    </template>
    <template #footer-wrapper>
      <c-loading-button-custom
        :loading="loading"
        color="primary"
        @click.native="submit"
      >
        Отправить
      </c-loading-button-custom>
    </template>
  </CModal>
</template>
<script>
import VSelectInput from "@/components/VSelectInput";
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: 'SendUserMessage',
  components: {
    VSelectInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      userList: [],
      user: null,
      message: '',
      loading: false,
    }
  },
  methods: {
    onSearch(search, loading) {
      if (search.length && search.length >= 3) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: debounce((loading, search, vm) => {
      axios
        .get('api/v1/users/autocomplete', {params: {term: search, telegram_id: 1}})
        .then((res) => {
          vm.userList = res.data.data
        })
        .finally(() => {
          loading(false)
        })
    }, 300),
    submit() {
      this.loading = true
      const fd = new FormData()
      fd.append('message', this.message)
      fd.append('user_id', this.user.id)
      axios.post('api/v1/tbot/send', fd)
        .then(res => {
          if(res?.data?.send){
            this.$toastr.success('Отправлено')
            this.$emit('closeErrorsModal')
          }else{
            this.$toastr.error(res?.data?.message || 'fail')
          }
          this.$emit('success')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
