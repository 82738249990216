<template>
  <div class="tg-notify d-flex flex-column">
    <div class="header">
      <h1 class="title">
        {{ $t('lk.tg_notifications.title') }}
        <CSpinner
          class="ml-3"
          v-if="loading"
          color="primary"
        />
      </h1>

      <CButton
        class="cbtn px-4"
        color="primary"
        :disabled="loading"
        @click="showSendMessageModal = true"
      >
        Отправить одиночное сообщение
      </CButton>

      <CButton
        class="cbtn px-4"
        color="primary"
        :disabled="loading"
        @click="showModal = true"
      >
        <CIcon name="cil-plus" />
        {{ $t('lk.tg_notifications.add') }}
      </CButton>
    </div>

    <CModal
      centered
      :closeOnBackdrop="false"
      :title="$t('lk.tg_notifications.titleModal')"
      add-content-classes="ccontent"
      :show="showModal"
      @update:show="closeEvent"
    >
      <div class="form-group mb-4">
        <div class="mt-2 c-textarea-wrap">
          <CTextarea
            v-model="notification.message"
            rows="6"
            :placeholder="$t('lk.tg_notifications.message')"
            class="c-textarea"
          />
          <button ref="emoji-trigger" class="emoji-trigger" @click="toggleEmojiPicker">
            <CIcon name="cil-happy" />
          </button>
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex w-100 m-0">
          <CLoadingButtonCustom
            class="cbtn mr-2 flex-grow-1"
            color="danger"
            :loading="loading"
            :disabled="loading"
            @click.native="showSendingConfirm = true"
          >
            <CIcon name="cil-bullhorn" class="my-0 mr-1" />
            {{ $t('lk.tg_notifications.send') }}
          </CLoadingButtonCustom>
          <CLoadingButtonCustom
            class="cbtn mr-2 flex-grow-1"
            color="success"
            :loading="loading"
            :disabled="loading"
            @click.native="save(false)"
          >
            <CIcon name="cil-save" class="my-0 mr-1" />
            {{ $t('lk.tg_notifications.save') }}
          </CLoadingButtonCustom>
          <CButton
            class="cbtn flex-grow-1"
            color="dark"
            :disabled="loading"
            @click="closeEvent"
          >
            {{ $t('lk.tg_notifications.cancel') }}
          </CButton>
        </div>
      </template>
    </CModal>

    <CModal
      centered
      :closeOnBackdrop="true"
      :title="$t('lk.tg_notifications.titleModal2')"
      add-content-classes="ccontent"
      :show="showSendingConfirm"
      @update:show="closeEvent"
    >
      <template v-slot:footer>
        <div class="d-flex w-100 m-0">
          <CLoadingButtonCustom
            class="cbtn mr-2 flex-grow-1"
            color="danger"
            :loading="loading"
            :disabled="loading"
            @click.native="save(true)"
          >
            <CIcon name="cil-bullhorn" class="my-0 mr-1" />
            {{ $t('lk.tg_notifications.send') }}
          </CLoadingButtonCustom>
          <CButton
            class="cbtn flex-grow-1"
            color="dark"
            :disabled="loading"
            @click="showSendingConfirm = false"
          >
            {{ $t('lk.tg_notifications.cancel') }}
          </CButton>
        </div>
      </template>
    </CModal>

    <CModal
      centered
      :closeOnBackdrop="true"
      :title="$t('lk.tg_notifications.titleModal2')"
      add-content-classes="ccontent"
      :show="showRemoveConfirm"
      @update:show="closeEvent"
    >
      <template v-slot:footer>
        <div class="d-flex w-100 m-0">
          <CLoadingButtonCustom
            class="cbtn mr-2 flex-grow-1"
            color="danger"
            :loading="loading"
            :disabled="loading"
            @click.native="remove"
          >
            {{ $t('lk.tg_notifications.delete') }}
          </CLoadingButtonCustom>
          <CButton
            class="cbtn flex-grow-1"
            color="dark"
            :disabled="loading"
            @click="closeEvent"
          >
            {{ $t('lk.tg_notifications.cancel') }}
          </CButton>
        </div>
      </template>
    </CModal>

    <SendUserMessage
      @close="showSendMessageModal = false"
      :show.sync="showSendMessageModal"
      @success="getItems()"
    />

    <tg-notifications-table
      :items="items"
      :loading="loading"
      :totalPages="totalPages"
      ref="table"
      @change-page="changePage"
      @openRemoveConfirm="showRemoveConfirm = true; notification = $event ? $event : null"
      @openShowModal="showModal = true; notification = $event ? $event : null"
    />
  </div>
</template>

<script>
import TgNotificationsTable from "@/components/tg_notifications/TgNotificationsTable";
import axios from "axios";
import TgNotification from '@/assets/js/tg_notifications/TgNotification.class';
import validate from '@/assets/js/tg_notifications/validateTgNotification';
import {EmojiButton} from '@joeattardi/emoji-button';
import {mapGetters} from "vuex";
import SendUserMessage from "@/components/tg_notifications/SendUserMessage";

const initialTgNotify = new TgNotification()

export default {
  name: "TgNotifications",
  components: {
    SendUserMessage,
    TgNotificationsTable
  },
  created() {
    this.getItems()
  },
  data() {
    return {
      TgNotification,
      showModal: false,
      showSendingConfirm: false,
      showRemoveConfirm: false,
      notification: initialTgNotify.copy(),
      loading: false,
      items: [],
      page: 1,
      totalPages: null,
      emojiPicker: null,
      showSendMessageModal: false,
    }
  },
  computed: {
    ...mapGetters({
      darkMode: 'darkMode'
    }),
    emojiTheme() {
      return this.darkMode ? 'dark' : 'light'
    }
  },
  watch: {
    page () {
      this.getItems()
    },
    darkMode() {
      if (this.emojiPicker) {
        this.emojiPicker.setTheme(this.emojiTheme)
      }
    }
  },
  mounted() {
    this.emojiPicker = new EmojiButton({
      strategy: 'fixed',
      position: 'right',
      theme: this.emojiTheme,
      i18n: this.$t('emojiPicker'),
      autoFocusSearch: false,
      initialCategory: 'recents'
    })

    this.emojiPicker.on('emoji', selection => {
      this.notification.message += selection.emoji
    })
  },
  beforeDestroy() {
    if (this.emojiPicker) {
      this.emojiPicker.destroyPicker()
    }
  },
  methods: {
    getItems() {
      this.loading = true
      axios.get('api/v1/tg_notifications', {
        params: {
          page: this.page
        }
      })
        .then(({ status, data }) => {
          if (status < 300) {
            this.items = data.data.map(v => new TgNotification(v))
            this.page = data?.meta?.current_page
            this.totalPages = data?.meta?.last_page
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    closeEvent () {
      this.showModal = false
      this.showSendingConfirm = false
      this.showRemoveConfirm = false
      this.notification = initialTgNotify.copy()
    },
    save (isSending) {
      const validateResult = validate(this.notification)
      if (!validateResult) return

      this.loading = true
      this.notification.save(isSending)
        .then(({status}) => {
          if (status < 300) {
            this.closeEvent()
          }
        })
        .finally(() => {
          this.loading = false
          if(this.page === 1){
            this.getItems()
          }else{
            this.$refs.table.page = 1
          }
        })
    },
    remove(){
      this.loading = true
      this.notification.delete()
        .then(({status}) => {
          if (status < 300) {
            this.closeEvent()
          }
        })
        .finally(() => {
          this.loading = false
          if(this.page === 1){
            this.getItems()
          }else{
            this.$refs.table.page = 1
          }
        })
    },
    changePage(page) {
      this.page = page
    },
    toggleEmojiPicker() {
      const emojiTrigger = this.$refs["emoji-trigger"]

      if (this.emojiPicker && emojiTrigger) {
        this.emojiPicker.togglePicker(emojiTrigger)
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.cc-icon {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.75rem;

  .title {
    font-size: 1rem;
    margin-bottom: .5rem;
    display: none;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1.25rem;
    flex-direction: column;
    align-items: flex-start;

    .cbtn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    .title {
      margin-bottom: 0;
      font-size: 1.25rem;
      display: block;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}
.cbutton {
  box-shadow: none !important;
  border: 1px solid var(--dark) !important;
  &:before {
    box-shadow: none !important;
  }
}

.c-textarea-wrap {
  position: relative;

  .emoji-trigger {
    background: none;
    border: none;
    width: 1.25rem;
    height: 1.25rem;
    padding: 0;
    position: absolute;
    top: .25rem;
    right: .25rem;
    outline: none;

    /deep/ svg {
      width: 100%;
      height: auto;
      color: var(--white);
    }
  }

  /deep/ textarea {
    padding-right: 1.75rem;
  }
}

.tg-notify {
  @include media-breakpoint-down(lg) {
    @media (min-height: 550px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 650px) {
      height: 100%;
    }
  }
}
</style>
